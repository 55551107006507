import React, { Component } from 'react';
import { renderText } from '../../../helpers/api'

class SubMenu extends Component {

  render () {
    const items = this.props.items;
    const level = this.props.depthLevel;
    const currentClass = ' current-menu-item';
    const handleClick = item => e => {
      const extern = item.classes.indexOf('nav-extern') !== -1;
      !extern && this.props.handleClick(e, item);
    };

    return (
      <ul className={'nc-sub-menu ' + (1 === level ? '-upper' : '-sub')}>
      {
        items.map(item => {
          const current = this.props.isCurrentMenuItem(item.url_components);
          if ('-clickable' === item.classes.join(' ')) {
            return false;
          } else {
            return (
              <li key={'menu_item_' + item.menu_item_id} className={'nc-sub-menu__item ' + (1 === level ? '-upper' : '-sub') + (current ? currentClass : '') + ' ' + (item.classes.join(' '))}>
                <a href={item.url} className={1 === level ? '-upper' : '-sub'} onClick={handleClick(item)} target={item.target}>{renderText(item.title)}</a>
                {                
                  item.children && <SubMenu items={item.children} depthLevel={level + 1} handleClick={this.props.handleClick} isCurrentMenuItem={this.props.isCurrentMenuItem} currentPath={this.props.currentPath}/>
                }
              </li>
            );
          }
        })
      }
      </ul>
    );
  }
}

export default SubMenu
