import { action, observable } from 'mobx';
import { WINDOW_EXISTS } from '../../helpers/viewport';
import { loadScript } from '../../helpers/browser';

export default class BlockDataStore {

  @observable
  allowInstagram = false;

  @observable
  allowVimeo = false;
  @observable
  allowYoutube = false;
  @observable
  allowTwitter = false;
  cookieStore = false;
  twitterLibSrc = "https://platform.twitter.com/widgets.js";
  twttrLoaded = false;
  isLoadingTwitter = false;
  instagramLibSrc = "https://www.instagram.com/embed.js";
  instaLoaded = false;
  isLoadingInstagram = false;


  /**
   * 
   * @param {CookieStore} cookieStore 
   */
  constructor(cookieStore) {
    this.cookieStore = cookieStore;
    this.allowVimeo = cookieStore.checkVimeo();
    this.allowYoutube = cookieStore.checkYoutube();
    this.allowTwitter = cookieStore.checkTwitter();
    this.allowInstagram = cookieStore.checkInstagram();
  }

 @action 
  setAllowVimeo() {
    this.allowVimeo = true;
    if (localStorage) {
      localStorage.setItem('nc.allowVimeo', true);
    }
    this.cookieStore.addConsentVimeo();
  }
  /**
   * Click Handler
   * @param {Event} e 
   * @returns bool
   */
  onClickAllowVimeo = (e) => {
    this.setAllowVimeo();
    e.preventDefault();
    return false;
  }

  @action 
  setAllowYoutube() {
    this.allowYoutube = true;
    if (localStorage) {
      localStorage.setItem('nc.allowYoutube', true);
    }
    this.cookieStore.addConsentYoutube();
  }
  /**
   * Click Handler
   * @param {Event} e 
   * @returns bool
   */
  onClickAllowYoutube = (e) => {
    this.setAllowYoutube();
    e.preventDefault();
    return false;
  }

  @action 
  setAllowTwitter() {
    this.allowTwitter = true;
    if (localStorage) {
      localStorage.setItem('nc.allowTwitter', true);
    }
    this.cookieStore.addConsentTwitter();
    this.initTwitterContent();
  }
  /**
   * Click Handler
   * @param {Event} e 
   * @returns bool
   */
  onClickAllowTwitter = (e) => {
    this.setAllowTwitter();
    e.preventDefault();
    return false;
  }

  initTwitterContent(){
    if (WINDOW_EXISTS && !this.isLoadingTwitter) {
      this.isLoadingTwitter = true;
      loadScript(this.twitterLibSrc)
        .then((event) => {
          window.twttr.widgets.load( document.getElementsByClassName("twitter-tweet"));
          this.twttrLoaded = true;
          this.isLoadingTwitter = false;
        }
      );
    }
  }

  loadTwitterWidgets() {
    if(this.allowTwitter) {
      if (this.twttrLoaded) {
        console.log('loadTwitterWidgets', window.twttr.widgets.load( document.getElementsByClassName("twitter-tweet")));
      } else {
        this.initTwitterContent();
      }
    }
  }

  @action 
  setAllowInstagram() {
    this.allowInstagram = true;
    if (localStorage) {
      localStorage.setItem('nc.allowInstagram', true);
    }
    this.cookieStore.addConsentInstagram();
    this.initInstagramContent();
  }
  /**
   * Click Handler
   * @param {Event} e 
   * @returns bool
   */
  onClickAllowInstagram = (e) => {
    this.setAllowInstagram();
    e.preventDefault();
    return false;
  }

  initInstagramContent(){
    if (WINDOW_EXISTS && !this.isLoadingInstagram) {
      this.isLoadingInstagram = true;
      loadScript(this.instagramLibSrc)
        .then((event) => {
          // window.insta.widgets.load( document.getElementsByClassName("instagram-media"));
          window.instgrm.Embeds.process()
          this.instaLoaded = true;
          this.isLoadingInstagram = false;
        }
      );
    }
  }

  loadInstagramWidgets() {
    //console.log(this.allowInstagram);
    if(this.allowInstagram) {
      if (this.instaLoaded) {
        // console.log('loadInstagramWidgets', window.insta.widgets.load( document.getElementsByClassName("instagram-media")));
      } else {
        this.initInstagramContent();
      }
    }
  }

}