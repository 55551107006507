import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CircleMarker, Popup, Pane } from 'react-leaflet';
import { COORDINATIONS } from '../HomeMap/Schools';
import { renderText } from '../../../helpers/api';

@inject('filterStore')
@observer
export default class HomeMap extends Component {
  render() {
    const schoolDetails = COORDINATIONS;
    const store = this.props.filterStore
    const markerItems = schoolDetails.map((detail, i) => {
      if (! detail.coordinates || ! detail.name || ! store.panes.get('coordinations').active) {
        return null;
      }

      return (
        <CircleMarker center={detail.coordinates} color="#fff" weight="1" fillColor="#EFA400" fillOpacity="1" radius={5} key={i}>
          <Popup maxWidth="300">
            <p>
              <strong>{detail["name"]}</strong>
              <br/>
              <small>{detail["Region"]}</small>
              <br/>
              {renderText(detail["Verein"])}
              <br/>
              <br/>
              {detail["Straße"]}
              <br/>
              {detail["PLZ"]} {detail["Ort"]}
              <br/>
              Tel: {detail["Tel"]}
              <br/>
              Mail: {detail["Mail"]}
            </p>
          </Popup>
        </CircleMarker> 
      )
    });
    return (
      <Pane>
        {markerItems}
      </Pane>
    );
  }
}
