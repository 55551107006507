import { action, observable } from 'mobx';


export default class SearchStore {

  /**
   * @module wpapi
   * wpapi.WPAPI
   */
  api;
  @observable isFetched = false;
  @observable results = [];
  @observable isFetching = false;
  @observable query = '';
  delay = 1000;
  /**
   * holder for timeout id
   */
  timeout = false;

  constructor( api ) {
    this.api = api;
    this.api.search = this.api.registerRoute('nc/v1', '/search/', { params: ['s']});
  }

  @action
  handleInputChange = (e) => {
    this.query = e.target.value;
    // clear the timeout
    clearTimeout(this.timeout);
    // start timing for event "completion"
    this.timeout = setTimeout(this.fetchIfNeeded, this.delay);
  }

  fetchIfNeeded = () => {
    if(this.query.length >= 3) {
      this.fetch();
    }
  }

  @action
  fetch() {
    this.isFetching = true;
    this.isFetched = false;
    this.api.search()
      .s(this.query)
      .then((posts) => {
        this.isFetched = true;
        this.isFetching = false;
        this.results = posts;
      })
      .catch(error => console.error(error));
  }
}

