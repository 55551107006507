import React, { Component } from 'react';
import {renderText } from '../../../helpers/api';
import Item from '../Item/Item';

class Layer extends Component {

  render() {
    const props = this.props;
    const items = props.nc_support_layer_items ? props.nc_support_layer_items : [];
    return (
      <div className="nc-support__layer">
        <div className="row no-gutters">
          <div className="col-10 offset-2 col-lg-8 offset-lg-2">
            <div className="row no-gutters">
              <div className="col-12 col-md-6">
                <h3>{renderText(props.nc_support_layer_title)}</h3>
                <div>{renderText(props.nc_support_layer_description)}</div>
              </div>
              {items.map((item, i) => <Item key={i} {...item}/>)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Layer;