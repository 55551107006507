/**
  Helper functions for communication with REST API
 */
import React from 'react';
import Helmet from 'react-helmet';
import Parser from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import { WINDOW_EXISTS } from './viewport';
import { Link } from 'react-router-dom';
import { UncontrolledCollapse } from 'reactstrap';
import YoutubeIFrameContent from '../components/elements/embed/YoutubeIFrameContent';
import VimeoIFrameContent from '../components/elements/embed/VimeoIFrameContent';
import TwitterContent from '../components/elements/embed/TwitterContent';
import InstagramContent from '../components/elements/embed/InstagramContent';
import ContentScroller from '../components/posts/ContentScroller/ContentScroller';
import InstagramScroller from '../components/posts/InstagramScroller/InstagramScroller';
import Form from '../components/contact/Form/Form';
import Homemap from '../components/maps/HomeMap/HomeMap'



const getHomeUrl = () => {
  if (typeof process.env.REACT_APP_REST_API_URL !== 'undefined') {
    return process.env.REACT_APP_REST_API_URL;
  }
  if ( typeof window !== 'undefined' && typeof window.location !== 'undefined' && window.location.hostname !== 'localhost' && !/192\.168\./.test(window.location.hostname)) {
    return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port: '/');
  }
  if (typeof process.env.REACT_APP_FALLBACK_REST_API_URL !== 'undefined') {
    return process.env.REACT_APP_FALLBACK_REST_API_URL;
  }
  return '/'
};

const getEndpointUrl = () => {
  return getHomeUrl() + 'wp-json/';
}

const getApiconfig = () => {
  const config = {
    endpoint: getEndpointUrl()
  };
  if ( process.env.NODE_ENV === 'production' && typeof window !== 'undefined' && typeof window.NC !== 'undefined' && window.NC.nonce ) {
    config.nonce = window.NC.nonce;
  }
  return config;
}

const getSiteName = () => {
  let siteName = '';
  if ( typeof window !== 'undefined' && typeof window.NC !== 'undefined' && window.NC.title ) {
    siteName = window.NC.title;
  }
  return siteName;
}


/**
 *
 * @param wpApi wpapi.WPAPI
 */
const setHeaders = (wpApi) => {
  if ('production' === process.env.NODE_ENV) {
    wpApi.setHeaders({
      'Cache-Control': 'public, max-age=' + ('undefined' !== typeof process.env.REACT_APP_CACHE_MAX_AGE ? process.env.REACT_APP_CACHE_MAX_AGE : 14400),
      'Pragma': 'public',
    });
  }
}

const createMarkup = (text) => {
  return {__html: text};
}

const renderTitle = (text, withSiteName) => {
  withSiteName = withSiteName !== false;
  if (text && text.rendered) {
    text = text.rendered;
  }
  if (withSiteName) {
    text = text + ' - ' + getSiteName();
  }
  if (text) {
    return  <Helmet><title>{Parser(text)}</title></Helmet>;
  } else {
    return null;
  }
}

const toggleCollapse = (e) => {
  e.currentTarget.classList.toggle('open');
  e.currentTarget.classList.toggle('closed');
}

const replaceCallback = (domNode) => {
  if (
    domNode.name === 'a' &&
    domNode.attribs &&
    domNode.attribs.href
  ) {
    const href = domNode.attribs.href;
    const isRouterLink = !/^http|^\/\/|^\/content|^#|^mailto/.test(href);

    if (isRouterLink) {
      const attr = attributesToProps(domNode.attribs);
      const {href, ...props } = attr;
      const backLocation = getBackLocation();
      const to = 
        backLocation ?
        {
          pathname: href,
          state: {'backLocation': backLocation}
        } :
        href;
      return <Link to={to} {...props}>{domToReact(domNode.children)}</Link>
    }
    const c = parseUrlComponents(href);
    if (getHomeUrl().indexOf(c.hostname) !== -1) {
      const attr = attributesToProps(domNode.attribs);
      const {href, ...props } = attr;
      const backLocation = getBackLocation();
      let to;
      if ('mailto:' === c.protocol || 'tel:' === c.protocol) {
        to =  c.protocol + c.pathname;
        return <a href={to} {...props}>{domToReact(domNode.children)}</a>;
      } else {
        to = 
        backLocation ?
        {
          pathname: c.pathname,
          state: {'backLocation': backLocation}
        } :
        c.pathname;
        return <Link to={to} {...props}>{domToReact(domNode.children)}</Link>;
      }
    }
  } else if (
    domNode.name === 'div' &&
    domNode.attribs &&
    domNode.attribs.class &&
    domNode.attribs.id &&
    (
      domNode.attribs.class.split(" ").indexOf('nc-collapse') !== -1
    ) &&
    domNode.children
  ) {
    const children = [];
    for (let i=0; i < domNode.children.length; i++) {
      if (domNode.children[i].name === 'div') {
        const key = domNode.attribs.id + i;
        // const childNodes = domNode.children[i].children.filter(node => node.type === 'tag');
        const childNodes = domNode.children[i].children.filter(node => node.name === 'span');
        const collapse = (
          <div key={key} className={childNodes[2] ? 'nc-collapse__item -has-img' : 'nc-collapse__item'}>
                {childNodes[2] && childNodes[2].children ? (
                  <a className="nc-collapse__toggler closed" color="primary" id={key + 'toggler'} onClick={toggleCollapse}>              
                    <div className="-img">{domToReact(childNodes[2].children)}</div>
                    <div className="d-flex">
                      <div className="flex-shrink-1 -toggler-icon"><span></span></div>
                      <h2 className="w-100">{domToReact(childNodes[0].children)}</h2>
                    </div>
                  </a>
                ) : (
                  <a className="nc-collapse__toggler closed" color="primary" id={key + 'toggler'} onClick={toggleCollapse}>              
                    <div className="flex-shrink-1 -toggler-icon"><span></span></div>
                    <h2 className="w-100">{domToReact(childNodes[0].children)}</h2>
                  </a>
                )}
              <UncontrolledCollapse className="" toggler={key + 'toggler'}>
                <div className="nc-collapse__content ">
                  {domToReact(childNodes[1].children)}
                </div>
              </UncontrolledCollapse>
          </div>
        );
        children.push(collapse);
      }
    }
    return (
      <div className={domNode.attribs.class} id={domNode.attribs.id}>
        <div className="row">
          <div className="col-12">
            {children}
          </div>
        </div>

      </div>
    );
    // embeded Youtube Video
  } else if (
    domNode.name === 'iframe' &&
    domNode.attribs && 
    domNode.attribs.src &&
    /youtube|vimeo/i.test(domNode.attribs.src)
  ) {
    // search the thumbnail
    let found = false;
    let search = domNode.parent;
    let thumbnail = false;
    let type = 'vimeo';
    do {
      if (
        search.attribs && 
        search.attribs.class.split(" ").indexOf('entry-content-asset') !== -1 &&
        search.attribs['data-img']
      ) {
        found = true;
        thumbnail = search.attribs['data-img'];
        type =  search.attribs['data-type']
      }
      search = search.parent ? search.parent : false;
    } while(search && !found);

    if ('vimeo' === type) {
      return <VimeoIFrameContent {...domNode} type={type} thumbnail={thumbnail}/>
    } else {
      return <YoutubeIFrameContent {...domNode} type={type} thumbnail={thumbnail}/>
    }
  // look for twitter embed?
  } else if (
    domNode.name === 'figure' &&
    domNode.attribs &&
    domNode.attribs.class &&
    domNode.attribs.class.split(" ").indexOf('wp-block-embed-twitter') !== -1
  ) {
    return <TwitterContent attribs={domNode.attribs}>{domNode.children}</TwitterContent>
  // look for instagram element
  } else if(
    domNode.name === 'figure' &&
    domNode.attribs &&
    domNode.attribs.class &&
    domNode.attribs.class.split(" ").indexOf('wp-block-embed-instagram') !== -1) {
      return <InstagramContent attribs={domNode.attribs}>{domNode.children}</InstagramContent>
  // look for embed content
  } else if(
    domNode.name === 'div' &&
    domNode.attribs &&
    domNode.attribs.class &&
    domNode.attribs.class.split(" ").indexOf('-embed') !== -1) {
      return <InstagramContent attribs={domNode.attribs}>{domNode.children}</InstagramContent>
  // look for intagramm slider
  } else if(
    domNode.name === 'div' &&
    domNode.attribs &&
    domNode.attribs.class &&
    domNode.attribs.class.split(" ").indexOf('-instagram') !== -1) {
      const childNodes = domNode.children.filter(node => node.name === 'div');
      return (
        <InstagramScroller attribs={attributesToProps(domNode.attribs)}>
          {domToReact(childNodes, parserOptions)}
        </InstagramScroller>
      );
    // look for content slider .nc-content-slider
  } else if(
    domNode.name === 'div' &&
    domNode.attribs &&
    domNode.attribs.class &&
    domNode.attribs.class.split(" ").indexOf('nc-content-slider') !== -1) {
      const childNodes = domNode.children.filter(node => node.name === 'div');
      return (
        <ContentScroller attribs={attributesToProps(domNode.attribs)}>
          {domToReact(childNodes, parserOptions)}
        </ContentScroller>
      );
  // look for map element
  } else if(
    domNode.name === 'div' &&
    domNode.attribs &&
    domNode.attribs.class &&
    domNode.attribs.class.split(" ").indexOf('setmap') !== -1) {
      return (
        <Homemap/>
      );
    // look for contact form 7 form element
    } else if(
    domNode.name === 'form'
    ){
    return (
      <Form attribs={attributesToProps(domNode.attribs)}>
        {domToReact(domNode.children, parserOptions)}
      </Form>
    );
    // look for contact form 7 input elments 
  } else if(
    domNode.name === 'input'
  ){
    const node = domNode;
    const  {value, ...attribs} = domNode.attribs;
    if (undefined !== value) {
      return <input defaultValue={value} {...attributesToProps(attribs)} />
    } else {
      return node;
    }
  }
  
};

const parserOptions = {
  replace: replaceCallback
}

const renderText = (text) => {
  if (text) {
    if (text.rendered) {
      text = text.rendered;
    }
    return  Parser(text, parserOptions);
  } else {
    return null;
  }
}

const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {}
};

const parseUrlComponents = url => {
  const parser = document.createElement("a");
    parser.href = url;
    return {
      protocol: parser.protocol,
      hostname: parser.hostname,
      port: parser.port,
      pathname: parser.pathname,
      search: parser.search,
      hash: parser.hash,
      host: parser.host,
    }
}

/**
 * returns a location object with current pathname and noScroll state
 * {
 *   pathname: 'current/path'
 *   state: { noScroll: true }
 * }
 * @returns false|Object
 */
const getBackLocation = () => {
  let backLocation = false;
  if ( WINDOW_EXISTS && window.location && window.location.pathname) {
    backLocation = {
      pathname: window.location.pathname,
      state: { noScroll: true }
    };
  }
  return backLocation;
}

export {
  getHomeUrl,
  getEndpointUrl,
  getApiconfig,
  getSiteName,
  setHeaders,
  createMarkup,
  renderTitle,
  renderText,
  parserOptions,
  getQueryStringParams,
  parseUrlComponents,
  getBackLocation,
};
