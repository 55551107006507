import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import BlockButton from './BlockButton';
/**
 * vimeo iframe component with blocker for iframe in Single Vimeo Post Component
 * for wp-embeded vimeo videos
 * @see <SingleVideo/>
 */
@inject('blockDataStore')
@observer
export default class VimeoIFrameSingle extends Component {

  render () {
    const allowVimeo = this.props.blockDataStore.allowVimeo;
    return (
      <div className="-video-wrapper">
        <div className="aspect-content">
          {!allowVimeo ? (
            <BlockButton thumbnail={this.props.img} provider="vimeo" onClick={this.props.blockDataStore.onClickAllowVimeo} type="single"/>
          ) : (
            <iframe src={this.props.src} title={this.props.title} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen>
              {this.props.title}
            </iframe>
          )}
        </div>
      </div>
    )
  }
}