import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject('filterStore')
@observer
class SchoolTypeFilter extends Component {

  onToggleFilter = (cat, event) => {
    // console.log(cat, event);
    this.props.dismissPopup();
    const categories = this.props.filterStore.categories;
    // all cats are active, show clicked cat exclusive
    if ( this.props.filterStore.isMapCatsAllActive ) {
      for ( const c of categories) {
        c[1].active = false;
      }
      cat.active = true;
    // clicked cat is the only active cat, show all cats
    } else if( 1 === this.props.filterStore.mapCatsActiveCount && cat.active){
      for ( const c of categories) {
        c[1].active = true;
      }
    } else {
      cat.active = !cat.active;
    }
  }
  render() {
    const categories = this.props.filterStore.categories;
    return (
      <div className="nc-map-filter-schools">
        <div className="nc-map-filter-schools__item-wrapper -caption"><span className="nc-map-filter-schools__item">Karte filtern:</span></div>
        {[...categories].map( (cat,i) => {
          return (<div className={'nc-map-filter-schools__item-wrapper '} key={cat[0]} onClick={this.onToggleFilter.bind(this, cat[1])}><span className={'nc-map-filter-schools__item bg-' + cat[0] +  (cat[1].active ? ' active' : ' inactive')}>{cat[1].name}</span></div>);
        })}
      </div>
    );
  }
}

export default SchoolTypeFilter
