import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import domToReact from 'html-react-parser/lib/dom-to-react';
import BlockButton from './BlockButton';

/**
 * instagram iframe component with blocker for iframe replacement in react html parser
 * for wp-embeded instagram posts
 * @see renderText
 * @see parserOptions
 */
@inject('blockDataStore')
@observer
export default class InstagramContent extends Component {

  componentDidMount(){
    this.props.blockDataStore.loadInstagramWidgets();
    // window.instgrm.Embeds.process();
  }

  render () {
    const allowInstagram = this.props.blockDataStore.allowInstagram;
    if (!allowInstagram) {
      return (
        <figure className={this.props.attribs.class}>
          <div className="wp-block-embed__wrapper">
            <div className="entry-content-asset">
              <BlockButton provider="instagram" onClick={this.props.blockDataStore.onClickAllowInstagram}/>
            </div>
          </div>
        </figure>
      );
    } else {
      return (
        <figure className={this.props.attribs.class}>
          {domToReact(this.props.children)}
        </figure>);
    }
  }
}