import { action, observable } from 'mobx';
import { matchPath } from 'react-router-dom';
import { getCurrentRoute } from '../routes/routes';


export default class ArchivesStore {

  /**
   * @module wpapi
   * wpapi.WPAPI
   */
  api;
  data = [];
  @observable isFetched = new Map();
  isFetching = [];
  slug = '';
  @observable
  currentRoute = {}

  constructor( api, routerStore ) {
    this.api = api;
    this.routerStore = routerStore;

    // this.getCurrentMatch();
  }

  getCurrentMatch() {
    this.match = matchPath(this.routerStore.location.pathname, {path: '/:id([\\w-]*)'});
    this.slug = this.match && '' !== this.match.params.id ? this.match.params.id : 'homepage';
  }

  @action
  fetch() {
    const route = this.currentRoute;
    const {path, apiParams} = route;
    this.isFetching[path] = true;

    const params = {
      post_type : apiParams.postType,
      type: apiParams.type,
    };
    if ('taxonomy' === apiParams.type) {
      params[apiParams.restBase] = apiParams.termId
    }

    this.api.posts = this.api.registerRoute(
      'nc/v1',
      '/posts/',
      { params: ['param']
    });
    this.api.posts()
      .param(params)
      .then((posts) => {
        if ( posts ) {
          this.data[route.path] = posts;
        } else {
          this.data[route.path] = false;
        }

        this.isFetched.set(route.path, true);
        this.isFetching[route.path] = false;
      })
      .catch(error => console.error(error));

    ;
  }

  fetchIfNeeded() {
    this.setCurrentRoute();
    const route = this.currentRoute;
    if ( !this.isFetching[route.path] && !this.isFetched.get(route.path)) {
      this.fetch();
    }
  };

  /**
   * fetch by path, for lazy loading archive data, independently from router location
   * @param {Route Object} route 
   */
  @action
  fetchTermArchiveIfNeeded( route ) {
    if ( route.path && !this.isFetching[route.path] && !this.isFetched.get(route.path)) {
      this.currentRoute = route;
      this.fetch();
    }
  };

  @action
  setCurrentRoute() {
    const route = getCurrentRoute();
    if ( 
      route &&
      route.apiParams &&
      -1 !== ['post_type_archive', 'taxonomy'].indexOf(route.apiParams.type) &&
      route.path !== this.currentRoute.path
    ) {
      this.currentRoute = route;
    }
  }
}

