import { action, observable } from 'mobx';

export default class SidebarsStore {

  /**
   * @module wpapi
   * wpapi.WPAPI
   */
  api
  data = {
    posts: {},
    ids: [],
    meta: {},
  };
  @observable isFetched = new Map();
  isFetching = [];
  slug = 'sidebar-footer';

  constructor( api ) {
    this.api = api;
  }


  @action
  fetch(params) {
    // check for object param
    let { slug } = params;
    this.isFetching[slug] = true;
    this.api.sidebars = this.api.registerRoute('nc/v1', '/sidebars/(?P<id>[\\w-]+)', { params: ['param']});
    this.api.sidebars().id(slug)
      .then((post) => {
        if ( post && post.hasOwnProperty(slug) ) {
          this.data.posts[slug] = post[slug];
        } else {
          this.data.posts[slug] = false;
        }
        this.isFetched.set(slug, true);
        this.isFetching[slug] = false;
      })
      .catch(error => console.error(error));
  }

  fetchIfNeeded(slug) {
    slug = '' !== slug ? slug : 'sidebar-footer';
    if ( !this.isFetching[slug] && !this.isFetched.get(slug)) {
      this.fetch({slug: slug});
    }
  }
}

