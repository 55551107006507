import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {renderText } from '../../../helpers/api';
import Img from '../../elements/Img/Img';
import ExtLink from '../../elements/ExtLink/ExtLink';

class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle = () => {
    this.setState({modal: !this.state.modal})
  }

  getLink = () => {
    const props = this.props;
    if(!props.nc_support_layer_items_link){
      return null;
    } else {
      if (props.nc_support_layer_items_link.title) {
        return <ExtLink className="-link" target={props.nc_support_layer_items_link.target} href={props.nc_support_layer_items_link.url}>{renderText(props.nc_support_layer_items_link.title)}</ExtLink>
      } else {
        return <ExtLink className="-link" target={props.nc_support_layer_items_link.target} href={props.nc_support_layer_items_link.url}>{renderText(props.nc_support_layer_items_link.url)}</ExtLink>
      }
    }
  }

  render() {
    const props = this.props;
    return (
      <div className="col-12 col-md-6">
        <div className="nc-support__item">
          <div className="-icon-wrapper">
            <button className="-icon"><Img onClick={this.toggle} src={props.nc_support_layer_items_icon.url} alt={props.nc_support_layer_items_icon.alt}/></button>
          </div>
          <div className="-text">{renderText(props.nc_support_layer_items_title)}</div>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="nc-support__modal">
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <ModalBody>
            <div>{renderText(props.nc_support_layer_items_text)}</div>
            {this.getLink()}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default Item;