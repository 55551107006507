import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MainMenu from '../../components/nav/MainMenu/MainMenu';
import MobileMenu from '../../components/nav/MobileMenu/MobileMenu';
import SubMenu from '../../components/nav/SubMenu/SubMenu';
import SearchWidget from '../../components/nav/SearchWidget/SearchWidget';
// import FirstAidTab from '../../components/elements/FirstAidTab/FirstAidTab';
import { observer, inject } from 'mobx-react';

@inject('navigationStore', 'routerStore')
@observer
class Header extends Component {
  menuItems = [];

  constructor(props) {
    super(props);
    this.menuItems = props.navigationStore.primaryNavigationItems;
    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
      searchIsOpen: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    // const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    let visible = true;
    // if( currentScrollPos > 20 ) {
    //   visible = prevScrollpos > currentScrollPos;
    // }

    this.setState({
      prevScrollpos: currentScrollPos,
      visible
    });
    if( currentScrollPos > 20 ) {
      this.setState({
        visible: false
      });
    }
  };  

  toggle = (toggler) => {
    console.log(toggler);
    let togglerStatus = this.state[toggler];
    this.setState({
      [toggler]: !togglerStatus
    });
  }

  render() {
    const currentPath = this.props.routerStore.location.pathname;
    const isCurrentMenuItem = this.props.navigationStore.isCurrentMenuItem;
    const handleClick = this.props.navigationStore.handleClick;
    const toggleNav = this.props.navigationStore.toggleNav;


    const show = ! this.props.navigationStore.isModal;

    return show ? (
      <header className={'nc-header' + (this.props.navigationStore.navIsActive ? ' -open-nav' : '') + (!this.state.visible ? " nc-header__small": "" )}>

        <div className={'nc-header__search-container w-100 collapse ' + (this.state.searchIsOpen? 'show' : 'hide')}>                
          <div className="row">
            <div className="col-12">
              <div className="row pb-3 pb-lg-5">
                <div className="col-4 offset-8">
                  <div className="d-flex justify-content-end" onClick={() => this.toggle('searchIsOpen')}>
                    <span className={'navbar-toggler-icon' + (this.state.searchIsOpen ? ' is_active' : '')}></span>
                  </div>
                </div>
              </div>
              <SearchWidget/>
            </div>
          </div>
        </div>

        <div className={'-mainnav-wrapper row ' + (this.props.navigationStore.navIsActive ? '' : '')}>
          <div className="col-6 col-lg-3">
            <Link className="nc-header__logo" to='/'>Schule ohne Rassismus - Schule mit Courage</Link>
          </div>
          <div className="col-6 col-lg-9">
              <div className="d-flex flex-column justify-content-end">
                <div className="d-block -search text-right" onClick={() => this.toggle('searchIsOpen')}><div className="nc-header__search">Suche</div></div>                  
                <div className="d-lg-flex justify-content-end d-none nc-main-nav__desktop-menu">
                  <div className="d-flex">
                    <MainMenu items={this.menuItems} depthLevel={0} isCurrentMenuItem={isCurrentMenuItem} handleClick={handleClick} currentPath={currentPath} />
                    <div className="-small-search" onClick={() => this.toggle('searchIsOpen')}><div className="nc-header__search">Suche</div></div>                  
                  </div>
                  
                </div>
                <div className="d-flex d-lg-none justify-content-end">
                  <span onClick={toggleNav} className="navbar-toggler-text">MENU</span> <span onClick={toggleNav} className={'navbar-toggler-icon' + (this.props.navigationStore.navIsActive ? ' is_active' : '')}></span>
                </div>
                
              </div>
            </div>
            <div className={'w-100 ' + (this.props.navigationStore.navIsActive ? 'collapse show' : 'collapse hide')}>
              <div className="nc-header__mobile-menu d-lg-none"><MobileMenu items={this.menuItems} depthLevel={0} isCurrentMenuItem={isCurrentMenuItem} handleClick={handleClick} currentPath={currentPath} /></div>
              <div className="nc-header__desktop-submenu d-none d-lg-block"><SubMenu items={this.menuItems} depthLevel={1} isCurrentMenuItem={isCurrentMenuItem} handleClick={handleClick} currentPath={currentPath} /></div>

            </div>
        </div>
      </header>
    ) : (
      null
    );
  }
}
export default Header;
