import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { parseUrlComponents, getHomeUrl } from '../../../helpers/api';

export default class ExtLink extends Component {

  render() {
    const {title, href, target, className, ...props } = this.props;
    const urlComponents = parseUrlComponents(href);
    if ('_blank' === target || getHomeUrl().indexOf(urlComponents.hostname) === -1) {
      return <a className={className ? className : ''} href={href} rel="noopener noreferrer" target="_blank" title={title} {...props}>{this.props.children}</a>
    } else {
      return <Link className={className ? className : ''} to={urlComponents.pathname} title={title} {...props}>{this.props.children}</Link>
    }
  }
}