import React, { Component } from 'react';
import {renderText} from '../../../helpers/api';
import { Collapse } from 'reactstrap';

class CooperationPartners extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rassismus: true,
      antisemitismus: true,
      rechtspopulismus: true,
      nationalsozialismus: true,
      migration: true,
      hatespeech: true,
      mobbing: true,
      toleranz: true,
      demokratiebildung: true,
      verschwoerungsideologien: true,
      sexismus: true,
      klassismus: true,
      partizipation: true,
      nachhaltigkeit: true,
      kolonialismus: true,
      antiromaismus_antiziganismus: true,
      islam_muslimfeindlichkeit: true,
      showPartners: true,
      isOpen: false, 
      setIsOpen: false
    };
  }

  toggle = (toggler) => {
    let togglerStatus = this.state[toggler];
    this.setState({
      [toggler]: !togglerStatus
    });
  }

  render() {
    const partners = this.props.post;
    let topics = {};
    let active = false;
    let activeCount = 0;
    const filters = partners.map((filter, i) => 
      filter.koop_topic.map((topic, j) => {    
        if (!topics[topic.value] ) {
          topics[topic.value]={slug:topic.value};
          return (<div className={this.state[topic.value] ? 'nc-partners__toggler -active' : 'nc-partners__toggler'} key={j} onClick={() => this.toggle(topic.value)}>{topic.label}</div>)
        }
        return false;
      })
    );

    const partnerList = partners.map((partner, i) => {
      active = false;
      partner.koop_topic && partner.koop_topic.map((topic) => {
        if( this.state[topic.value] ) {
          active = true;
        } 
        return false;
      });
      if (active) {
        activeCount++;
      }
      return <div key={i}>    
        <div className={active ? 'd-block nc-partner-box' : 'd-none nc-partner-box' } key={i}>
          {/* {console.log(active)} */}
          <div className="row">
            <div className="col-12 col-md-6">
              <h3>{partner.koop_name && partner.koop_name}</h3>
            </div>
            <div className="col-12 col-md-6 d-flex align-items-center justify-content-end">
              {partner.koop_approved && partner.koop_approved[0] && (                
                <div className="nc-partner-box__approved"></div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
                {partner.koop_logo && (
                  partner.koop_website ? (
                    <div className="nc-partner-box__logo">
                      <a href={partner.koop_website.url} target={partner.koop_website.target ? '_blank' : '_self'}>
                        <img src={partner.koop_logo.sizes.medium} className="img-fluid" alt={partner.koop_name} />
                      </a>
                    </div>
                  ) : (
                    <div className="nc-partner-box__logo">
                      <img src={partner.koop_logo.sizes.medium} className="img-fluid" alt={partner.koop_name} />
                    </div>
                  )
                )}
                <h4>Themenfeld</h4>
                <div className="nc-partner-box__topic">
                  {partner.koop_topic && partner.koop_topic.map((topic, i) => {
                    return <span className="-item" key={i}> {topic.label} </span>;
                  })}
                </div>
                <h4>Zielgruppe</h4>
                <div className="nc-partner-box__target">
                  {partner.koop_target_group && partner.koop_target_group.map((topic, i) => {
                    if( 'schueler_innen' === topic ) {
                      topic = 'Schüler_innen';
                    } else if( 'lehrkraefte' === topic ) {
                      topic = 'Lehrkräfte';
                    } else if( 'eltern' === topic ) {
                      topic = 'Eltern';
                    } else if( 'schulsozialarbeiter_innen' === topic ) {
                      topic = 'Schulsozialarbeiter_innen';
                    } else if( 'multiplikator_innen' === topic ) {
                      topic = 'Multiplikator_innen';
                    }
                    return <span className="-item" key={i}> {topic} </span>;
                  })}
                </div>
                <h4>Region</h4>
                <div className="nc-partner-box__region">
                  {partner.koop_region && partner.koop_region}
                </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="nc-partner-box__description">
                <h4>{partner.koop_project && partner.koop_project}</h4>
                {renderText(partner.koop_desc)}
              </div>
              <div className="nc-partner-box__link">
                {partner.koop_website && (
                  <a href={partner.koop_website.url} target={partner.koop_website.target ? '_blank' : '_self'}>{partner.koop_website.title}</a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

    });
    return (
      <div>
        <div className="nc-partners__filter nc-collapse">
          <div className="nc-collapse__item">
            <div className="nc-collapse__toggler" onClick={() => this.toggle('isOpen')}>
                <div className="-toggler w-100">Kooperationspartner_innen nach Thema filtern</div>
                <div className="flex-shrink-1 -toggler-icon"><span></span></div>
            </div>
            <Collapse isOpen={this.state['isOpen']}>
              <div className="nc-collapse__content ">
                <div className="-filters">{filters}</div>
                <div onClick={() => this.toggle('isOpen')}><button className="mt-3 btn">Filter anwenden</button></div>
              </div>              
            </Collapse>
          </div>
        </div>

        <div className="nc-partners__amount">Anzahl der gewählten Partner: {activeCount}</div>
        
        {partnerList}
      </div>
    );

  }
}

export default CooperationPartners;
