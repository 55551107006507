import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CircleMarker, Popup, Pane } from 'react-leaflet';
import { SCHOOLS } from '../HomeMap/Schools';

@inject('filterStore')
@observer
export default class HomeMap extends Component {
  constructor(props) {
    super(props);
    this.props.filterStore.init(SCHOOLS);
  }

  render() {
    const schoolDetails = SCHOOLS;
    const store = this.props.filterStore
    const markerItems = schoolDetails.map((detail, i) => {
      if (! detail.coordinates || ! detail.Schulart || !store.panes.get('schools').active) {
        return null;
      }
      const schoolCats = detail.Schulart.split('|').map(s => s.trim());
      const isActive = schoolCats.filter(x => -1 !== store.activeCatsAsArray.indexOf(x)).length;
      if (!isActive) {
        return null;
      }
      const isClosed = 'geschlossen' === detail.status;
      const fillColor = isClosed ? "#ccc" : "#902584";
      const fillOpacity = isClosed ? 0.7 : 1;

      return (
        <CircleMarker center={detail.coordinates} color="#fff" weight="1" fillColor={fillColor} fillOpacity={fillOpacity} radius={5} key={detail['Lfd. Nr.']}>
          <Popup maxWidth="250">
            <p>
              <strong>{detail["Schule"]}</strong>
              <br/>
              ({detail["Schulart"]})
              <br/>
              Datum der Titelverleihung: {detail["Datum TV"]}
              <br/>
              {detail["Straße"]}, {detail["PLZ"]} {detail["Ort"]}
              <br/>
              {detail["Landkreis"]}, {detail["Region"]}
            </p>
            {detail["Patenschaft"] && <p>Patenschaft: {detail["Patenschaft"]}</p>}
            {detail.status && <p>{detail.status.toUpperCase()}</p>}
            <p><a href={detail["Homepage"]} rel="noopener noreferrer" target="_blank">Homepage</a></p>
          </Popup>
        </CircleMarker> 
      )
    });
    return (
      <Pane>
        {markerItems}
        <span className="nc-map__count">Anzahl Courage-Schulen: {schoolDetails.length}</span>
      </Pane>
    );
  }
}
