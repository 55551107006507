import React, { Component } from 'react';
import SubMenu from '../SubMenu/SubMenu';
import { observer, inject } from 'mobx-react';

@inject('navigationStore', 'routerStore')
@observer
class FooterNav extends Component {

  menuItems = [];

  constructor(props) {
    super(props);
    this.menuItems = props.navigationStore.footerNavigationItems;
  }

  render () {
    const currentPath = this.props.routerStore.location.pathname;
    const isCurrentMenuItem = this.props.navigationStore.isCurrentMenuItem;
    const handleClick = this.props.navigationStore.handleClick;

    return (
      <nav className="nc-footer__footernavi">
        {this.menuItems && <SubMenu items={this.menuItems} depthLevel={0} isCurrentMenuItem={isCurrentMenuItem} handleClick={handleClick} currentPath={currentPath}/>}
      </nav>
    );
  }
}

export default FooterNav
