import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import LoadingAnimation from '../../elements/LoadingAnimation/LoadingAnimation';
import { renderText } from '../../../helpers/api';

@inject('navigationStore', 'searchStore')
@observer
class SearchWidget extends Component {

  results = [];

  getSearchResults() {
    const handleClick = item => e => {
      this.props.navigationStore.handleClick(e, item);
    };
    if (this.props.searchStore.results.length){
      this.results =
        this.props.searchStore.results
        .map((post) => {
          return (
            <div key={post.ID} className="nc-search-results__item">
             <a href={post.url} onClick={handleClick(post)}>
              { post.search_result_content ?
                renderText(post.search_result_content)
              :
                <span>{renderText(post.post_title)}</span>
              }
            </a>
            </div>
          );
        })
    } else if (this.props.searchStore.isFetched){
      this.results = [
        <div key="no_result">keine Treffer für "{this.props.searchStore.query}"</div>
      ]
    }
    return this.results
  }

  render () {
    return (
      <div className="mb-5">
        <form className="nc-search-form" onSubmit={e => { e.preventDefault(); }}>
          <input
            className="search-field"
            placeholder="Suche ..."
            ref={input => this.search = input}
            onChange={this.props.searchStore.handleInputChange}
            value={this.props.searchStore.query}
          />
        </form>
        <div className={'nc-search-results '}>
          {this.props.searchStore.isFetching ?
            <LoadingAnimation/>
          :
            this.getSearchResults()
          }
        </div>
      </div>
    );
  }
}

export default SearchWidget;
