import React, { Component } from 'react';
import { renderText } from '../../../helpers/api'
import { observer, inject } from 'mobx-react';
import SubMenu from '../SubMenu/SubMenu'

@inject('navigationStore', 'routerStore')
@observer
class MobileMenu extends Component {

  constructor(props) {
    super(props);
    this.menuItems = props.navigationStore.primaryNavigationItems;
  }

  render () {
    const items = this.props.items;
    const level = this.props.depthLevel;
    const currentClass = ' current-menu-item';
    const handleClick = item => e => {
      const extern = item.classes.indexOf('nav-extern') !== -1;
      !extern && this.props.handleClick(e, item);
    };

    return (
      <ul className={!level ? 'nc-mobile-main-menu' : 'nc-mobile-sub-menu'}>
      {
        items.map(item => {
          const current = this.props.isCurrentMenuItem(item.url_components);

          return (
            <li key={'menu_item_' + item.menu_item_id} className={'nav_item ' + (!level ? 'nc-main-menu__item' : 'nc-sub-menu__item') + (current ? currentClass : '') + ' ' + (item.classes.join(' '))}>
              {'-clickable' === item.classes.join(' ') ? (
                <span>
                    <a href={item.url} className={1 === level ? '-upper' : '-sub'} onClick={handleClick(item)} target={item.target}>{renderText(item.title)}</a>
                    {item.children && <SubMenu items={item.children} depthLevel={level + 1} handleClick={this.props.handleClick} isCurrentMenuItem={this.props.isCurrentMenuItem} currentPath={this.props.currentPath}/>}
                </span>                
              ) : (
                  <span>
                    <span className="-not-clickable">{renderText(item.title)}</span>
                    {item.children && <SubMenu items={item.children} depthLevel={level + 1} handleClick={this.props.handleClick} isCurrentMenuItem={this.props.isCurrentMenuItem} currentPath={this.props.currentPath}/>}
                  </span>
              ) }
            </li>
          );
        })
      }      
      </ul>
    );
  }
}

export default MobileMenu
