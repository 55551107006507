import React, { Component } from 'react';
import { renderText } from '../../helpers/api';
import { inject, observer } from 'mobx-react';

@inject('quizStore')
@observer
export default class Result extends Component {

  store = {};
  evaluations = [];

  constructor(props){
    super(props);
    this.store = props.quizStore;
    this.evaluations = props.evaluations.reverse();
  }

  render() {
    if (!this.store.quizComplete) {
      return null;
    }
    const score = this.store.score;
    const evaluation = this.evaluations.find((e) => score >= e.nc_quiz_result_score);

    return (
      <div className="result">
        <div className="-score">{score} {1 !== score ? 'Punkte' : 'Punkt'}</div>
        <div className="-evaluation">{evaluation && renderText(evaluation.nc_quiz_result_text)}</div>
        <div className="-replay">
          <button type="button" className="btn btn-light" onClick={this.store.onClickReplay}>Nochmal</button>
        </div>
      </div>
    );
  }

}