import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { parseUrlComponents } from '../../../helpers/api';
import { WINDOW_EXISTS } from '../../../helpers/viewport';

@inject('routerStore')
class Form extends Component {

  formRef = React.createRef();

  componentDidMount() {
    if( WINDOW_EXISTS && window.wpcf7 ) {
      window.wpcf7.init(this.formRef.current);
    }
  }
  render() {
    const origActionObj = parseUrlComponents(this.props.attribs.action);
    const hash = origActionObj.hash ? origActionObj.hash : '';
    const {action, ...attribs} = this.props.attribs

    return (
      <form action={ this.props.routerStore.location.pathname + hash} {...attribs} ref={this.formRef}>
        {this.props.children}
      </form>
    );
  }

}

export default Form