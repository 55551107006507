/**
  Helper functions for communication with REST API
 */
import React from 'react';
import VisibiltySensor from 'react-visibility-sensor';

// Check if code is running in the browser (important for universal rendering)
const WINDOW_EXISTS = typeof window !== 'undefined';
const SCROLL_OFFSET = 50;

const isInViewPort = (element, e) => {
  // Check if element is in viewport
  // Small offset is added to prevent page jumping
  if (!WINDOW_EXISTS || !(element instanceof HTMLElement)) {
    return false;
  }
  const rect = element.getBoundingClientRect();
  const isTopAboveBottomEdge = rect.top < window.innerHeight + SCROLL_OFFSET;
  const isBottomBelowTopEdge = rect.top + rect.height > -SCROLL_OFFSET;

  if (!isTopAboveBottomEdge || !isBottomBelowTopEdge) {
    return false;
  } else {
    return true;
  }
};

/**
 * Check if element hits vertical center of viewport
 * @param {HTMLElement} element HTMLElement
 * @param {Event} e Event
 * 
 * @returns bool
 */
const isInCenterOfViewPort = (element, e) => {
  // Check if element hits  vertical center of viewport
  // Small offset is added to prevent page jumping
  if (!WINDOW_EXISTS || !(element instanceof HTMLElement)) {
    return false;
  }

  const rect = element.getBoundingClientRect();
  const isTopAboveCenter = rect.top < window.innerHeight/2;
  const isBottomBelowCenter = rect.bottom > window.innerHeight/2;

  if (!isTopAboveCenter || !isBottomBelowCenter) {
    return false;
  } else {
    return true;
  }
};

/**
 * Check if element hits  vertical top of viewport
 * Small offset is added to prevent page jumping
 * @param {HTMLElement} element HTMLElement
 * @param {Event} e Event
 * 
 * @returns bool
 */
const isInTopOfViewPort = (element, e) => {
  // Check if element hits  vertical center of viewport
  // Small offset is added to prevent page jumping
  if (!WINDOW_EXISTS || !(element instanceof HTMLElement)) {
    return false;
  }

  const rect = element.getBoundingClientRect();
  const isTopAboveTop = rect.top < SCROLL_OFFSET;
  const isBottomBelowTop = rect.bottom > window.innerHeight - SCROLL_OFFSET;

  if (!isTopAboveTop || !isBottomBelowTop) {
    return false;
  } else {
    return true;
  }
};

/**
 * a component to render children only, when in viewport
 * and new render only if props hav changed
 * @see https://reactjs.org/docs/react-api.html#reactmemo
 */
const Visible = React.memo(props => {
  const children = props.children;
  const once = !!props.once;
  let always = false;
  const partialVisibility = typeof props.partialVisibility !== 'undefined' ? props.partialVisibility : true;
  const onChange = (isVisible) => {
    if (once && isVisible) {
      always = true;
    }
  };
  return (
    <VisibiltySensor partialVisibility={partialVisibility} onChange={onChange}>
      {({isVisible}) => {
        return isVisible || always ? children : <span>&nbsp;</span>;
      }}
    </VisibiltySensor>
  )
});

export { isInViewPort, isInCenterOfViewPort, isInTopOfViewPort, WINDOW_EXISTS, Visible };
