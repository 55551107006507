import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import { renderTitle, renderText } from '../../helpers/api';
import { renderTitle } from '../../helpers/api';
import NoMatch  from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import LoadingAnimation from '../../components/elements/LoadingAnimation/LoadingAnimation';
import VimeoIFrameSingle from '../../components/elements/embed/VimeoIFrameSingle';
import YoutubeIFrameSingle from '../../components/elements/embed/YoutubeIFrameSingle'
import CloseButton from '../../components/elements/CloseButton/CloseButton';


@inject('pagesStore', 'navigationStore')
@observer
class SingleVideo extends Component {

  slug = '';
  store = {};
  /**
   * default backlocation
   */
  backLocation = {
    pathname: '/',
    state: { noScroll: true }
  };

  constructor(props) {
    super(props);
    this.store = this.props.pagesStore;
  }

  renderHead() {
    if (this.store.isFetched && this.store.data.posts[this.slug].title) {
      return renderTitle(this.store.data.posts[this.slug].title);
    } else {
      return null;
    }
  }

  getCurrentSlug = () => {
    if (this.props.match && this.props.match.params) {
      this.slug = this.props.location.pathname;
    }
  }
  componentDidMount() {
    this.store.fetchPathIfNeeded(this.slug);
    document.body.classList.add('video');
    this.props.navigationStore.enableIsModal();
  }
  componentWillUnmount() {
    document.body.classList.remove('video');
    this.props.navigationStore.disableIsModal();
  }

  componentDidUpdate() {
    this.store.fetchPathIfNeeded(this.slug);
  }

  render() {
    this.getCurrentSlug();
    const isFetched = this.store.isFetched.get(this.slug);
    const post = this.store.data.posts && this.store.data.posts[this.slug];
    const scrollToTop = !this.props.location.hash && (!this.props.location.state || !this.props.location.state.scrollTo);

    if ((isFetched && false === post) || (isFetched && !post)) {
      return <NoMatch location={this.props.location} />;
    }
    const video = post && post.fields.field_video_post_video;
    const backLocation = (this.props.location.state && this.props.location.state.backLocation) ? this.props.location.state.backLocation : this.backLocation;

    return (
      <div className="nc-page nc-video-post">
        <CloseButton backLocation={backLocation}/>
        { isFetched ? (
          <div className="nc-page__content -content">
            {scrollToTop && <ScrollToTop onHistoryPush={true}/>}
            {this.renderHead()}
            {/* <div className="nc-page__header">
              <h1>{renderText(post.title)}</h1>
            </div> */}
              <div className="row h-100 align-items-center">
                <div className="col-12 col-lg-8 offset-lg-2">
                  <div className="wp-block-embed">
                    <div className="-wrapper">
                      {video && 'vimeo' === video.type && <VimeoIFrameSingle {...video} title={post.title}/>}
                      {video && 'youtube' === video.type && <YoutubeIFrameSingle {...video} title={post.title}/>}
                    </div>
                  </div>
                </div>
              </div>
          </div>
        ) : 
          <LoadingAnimation/>
        }
      </div>
    );
  }
}

export default SingleVideo;
