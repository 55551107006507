import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle } from '../../helpers/api';
import LoadingAnimation from '../../components/elements/LoadingAnimation/LoadingAnimation';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import CloseButton from '../../components/elements/CloseButton/CloseButton';
import Quiz from '../../components/quiz/Quiz';

@inject('pagesStore', 'navigationStore')
@observer
export default class SingleQuiz extends Component {

  slug = 'quiz';
  store = {};
  backLocation = {
    pathname: '/rassismus/',
    state: { noScroll: true }
  };

  constructor(props) {
    super(props);
    this.store = this.props.pagesStore;
  }

  componentDidMount() {
    this.store.fetchIfNeeded(this.slug);
    document.body.classList.add('quiz');
    this.props.navigationStore.enableIsModal();
  }
  componentWillUnmount() {
    document.body.classList.remove('quiz');
    this.props.navigationStore.disableIsModal();
  }

  renderHead() {
    if (this.store.isFetched.get(this.slug) && this.store.data.posts[this.slug].title) {
      return renderTitle(this.store.data.posts[this.slug].title);
    } else {
      return null;
    }
  }

  render() {
    const isFetched = this.store.isFetched.get(this.slug);
    const post = this.store.data.posts[this.slug];
    const scrollToTop = !this.props.location.hash && (!this.props.location.state || !this.props.location.state.scrollTo);
    const backLocation = (this.props.location.state && this.props.location.state.backLocation) ? this.props.location.state.backLocation : this.backLocation;

    return (
      <div className={'nc-page quiz'}>
        <CloseButton backLocation={backLocation}/>
        { isFetched ? (
            <div className="nc-page__content -content">
              {scrollToTop && <ScrollToTop onHistoryPush={true}/>}
              {this.renderHead()}
              <div className="row">
                <div className="col-12 col-lg-8 offset-lg-2">{post.fields && <Quiz key={this.slug} slug={this.slug} {...post.fields}/>}</div>
              </div>
            </div>
          ) : (
            <LoadingAnimation/>
          )
        }
      </div>
    );
  }
}