import React, { Component } from 'react';
import { renderText } from '../../../helpers/api'
import { observer, inject } from 'mobx-react';

@inject('navigationStore', 'routerStore')
@observer
class MainMenu extends Component {

  constructor(props) {
    super(props);
    this.menuItems = props.navigationStore.primaryNavigationItems;
  }

  render () {
    const items = this.props.items;
    const level = this.props.depthLevel;
    const toggleNav = this.props.navigationStore.toggleNav;
    const currentClass = ' current-menu-item';
    const handleClick = item => e => {
      const extern = item.classes.indexOf('nav-extern') !== -1;
      !extern && this.props.handleClick(e, item);
    };

    return (
      <ul className={!level ? 'nc-main-menu' : 'nc-sub-menu'}>
      {
        items.map(item => {
          const current = this.props.isCurrentMenuItem(item.url_components);

          return (
            <li key={'menu_item_' + item.menu_item_id} className={'nav_item ' + (!level ? 'nc-main-menu__item' : 'nc-sub-menu__item') + (current ? currentClass : '') + ' ' + (item.classes.join(' '))}>
              {'-clickable' === item.classes.join(' ') ? (

                <a href={item.url} className={1 === level ? '-upper' : '-sub'} onClick={handleClick(item)} target={item.target}>{renderText(item.title)}</a>
              ) : (
                <span onClick={toggleNav}>{renderText(item.title)}</span>
              ) }
            </li>
          );
        })
      }      
      </ul>
    );
  }
}

export default MainMenu
