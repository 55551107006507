import React, { Component } from 'react';
// import VisibiltySensor from 'react-visibility-sensor';
import FooterNav from '../../components/nav/FooterNav/FooterNav';
import SocialNav from '../../components/nav/SocialNav/SocialNav';
import { inject, observer } from 'mobx-react';
import { renderText } from '../../helpers/api';

@inject('sidebarsStore', 'navigationStore', 'scrollStore')
@observer
class Footer extends Component {

  sidebar = [];
  firstAidRef = React.createRef();

  constructor(props) {
    super(props);
    this.sidebar = props.sidebarsStore;
  }

  componentDidMount() {
    const isFetched = this.props.sidebarsStore.isFetched.get('sidebar-footer');
    if(isFetched) {
      this.props.sidebarsStore.fetchIfNeeded('sidebar-firstaid');
    } else {
      this.props.sidebarsStore.fetchIfNeeded('sidebar-footer');
    }
    this.props.scrollStore.firstAidRef = this.firstAidRef;
  }

  componentDidUpdate() {
    const isFetched = this.props.sidebarsStore.isFetched.get('sidebar-footer');
    if(isFetched) {
      this.props.sidebarsStore.fetchIfNeeded('sidebar-firstaid');
    } else {
      this.props.sidebarsStore.fetchIfNeeded('sidebar-footer');
    }
  }

  render() {
    const show = ! this.props.navigationStore.isModal;
    const isFetched = this.props.sidebarsStore.isFetched.get('sidebar-footer');
    const isFetchedFirstaid = this.props.sidebarsStore.isFetched.get('sidebar-firstaid');

    return show ? (
      <div>
        <div className="nc-logos" ref={this.firstAidRef}>
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1 col-xxl-8 offset-xxl-2">
              <div className="nc-logos__content">
                { isFetchedFirstaid ? (
                  renderText(this.sidebar.data.posts['sidebar-firstaid'])
                ) : ( null )}
              </div>
            </div>
          </div>
        </div>

        <footer className="nc-footer">

          <div className="nc-footer__content">
            <div className="row pb-5">
              <div className="col-12 col-md-4">
                <div className="-copy">
                { isFetched ? (
                  renderText(this.sidebar.data.posts['sidebar-footer'])
                ) : ( null )}
                </div>
              </div>
              <div className="col-12 col-md-8">
                <h2>&nbsp;</h2>
                <SocialNav/>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-12">
                <FooterNav/>
              </div>
            </div>
          </div>
        </footer>
      </div>

    ) : (
      null
    );
  }
}
export default Footer;
