import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import domToReact from 'html-react-parser/lib/dom-to-react';
import BlockButton from './BlockButton';

/**
 * youtube iframe component with blocker for iframe replacement in react html parser
 * for wp-embeded youtube videos
 * @see renderText
 * @see parserOptions
 */
@inject('blockDataStore')
@observer
export default class TwitterContent extends Component {

  componentDidMount(){
    this.props.blockDataStore.loadTwitterWidgets();
  }

  render () {
    const allowTwitter = this.props.blockDataStore.allowTwitter;
    if (!allowTwitter) {
      return (
        <figure className={this.props.attribs.class}>
          <div className="wp-block-embed__wrapper">
            <div className="entry-content-asset">
              <BlockButton provider="twitter" onClick={this.props.blockDataStore.onClickAllowTwitter}/>
            </div>
          </div>
        </figure>
      );
    } else {
      return <figure className={this.props.attribs.class}>{domToReact(this.props.children)}</figure>;
    }
  }
}