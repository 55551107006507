import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject('filterStore')
@observer
class PanesFilter extends Component {

  onToggleFilter = (cat, event) => {
    this.props.dismissPopup();
    cat.active = !cat.active;
  }
  render() {
    const panes = this.props.filterStore.panes;
    return (
      <div className="nc-map-filter-type">
        <div className="nc-map-filter-type__item-wrapper -caption"><span className="nc-map-filter-type__item">Anzeigen:</span></div>
        {[...panes].map( (cat,i) => {
          return (<div className={'nc-map-filter-type__item-wrapper '} key={cat[0]} onClick={this.onToggleFilter.bind(this, cat[1])}><span className={'nc-map-filter-type__item bg-' + cat[0] +  (cat[1].active ? ' active' : ' inactive')}>{cat[1].name}</span></div>);
        })}
      </div>
    );
  }
}

export default PanesFilter
