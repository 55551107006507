import React, { Component } from 'react';
import { renderText } from '../../helpers/api';
import { inject, observer } from 'mobx-react';

@inject('sidebarsStore')
@observer
class NoMatch extends Component {

  constructor(props) {
    super(props);
    this.sidebar = props.sidebarsStore;
  }

  render() {
    this.props.sidebarsStore.fetchIfNeeded('sidebar-404');
    const isFetched = this.props.sidebarsStore.isFetched.get('sidebar-404');
    
    return (
      <div className={'nc-page '}>
        <div className={'nc-page__content '}>
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1 col-xxl-8 offset-xxl-2">
              <div className={'nc-page__content-wrapper'}>
                { isFetched ? (
                  renderText(this.sidebar.data.posts['sidebar-404'])
                ) : ( null )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NoMatch;