import React, { Component } from 'react';


export default class BlockButton extends Component {

  getContent = () => {
    const provider = this.props.provider;
    let content = '';
    if ('vimeo' === provider) {
      content = (
        <span>
          <span className="-blocker-text">Mit dem Laden des Videos akzeptieren Sie die Datenschutzerklärung von Vimeo.</span>
        </span>
      );
    } else if('twitter' === provider) {
      content = (
        <span>
          <span className="-blocker-text">Mit dem Laden des Tweets akzeptieren Sie die Datenschutzerklärung von Twitter.</span>
        </span>
      );
    } else if('instagram' === provider) {
      content = (
        <span>
          <span className="-blocker-text">Mit dem Laden des Artikels akzeptieren Sie die Datenschutzerklärung von <strong>Instagram</strong>.</span>
        </span>
      );
    } else {
      content = (
        <span>
          <span className="-blocker-text">Mit dem Laden des Videos akzeptieren Sie die Datenschutzerklärung von YouTube.</span>
        </span>
      );
    }
    return content;
  }

  render () {
    const isTeaser = 'teaser' === this.props.type;
    const isSingle = 'single' === this.props.type;
    const classes = isTeaser ? ' btn-sm' : '';

    const style = this.props.thumbnail ? {
      backgroundImage: `url(${this.props.thumbnail})`,
    } : {};
    return(
      <div className={'iframe-block' + (isTeaser ? ' teaser' : '') + (isSingle ? ' single' : '')} style={style}>
        <div className={'iframe-block__caption' + classes}>
          <button className={'btn' + classes} onClick={this.props.onClick}>Inhalt laden</button>
          <br/>
          {this.getContent()}
          <a href="/datenschutz/">Mehr erfahren</a>
        </div>
      </div>
    );
  }
}