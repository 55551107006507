import React, { Component } from 'react';
import { renderText } from '../../helpers/api';
import { inject, observer } from 'mobx-react';
import './transitions.css';

@inject('quizStore')
@observer
export default class Question extends Component {

  letters = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];

  getAnswers = () => {
    const onClickAnswer = index => e => {
      if (this.props.quizStore.canClick) {
        this.props.quizStore.onClickAnswer(e, index);
      }
    };
    return this.props.nc_quiz_answers.map(
      (answer, i) => {
        const isActive = this.props.step === this.props.quizStore.currentStep;
        const correct = answer.nc_quiz_answers_answer_zimbo;
        const locked = this.props.locked;
        // set transition class
        let transition = '';
        if (isActive && (false !== locked)) {
          // this is the locked answer
          if (locked === i) {
            transition += ' locked';
            if (correct) {
              transition += ' correct-enter-active';
            } else {
              transition += ' wrong-enter-active';
            }
          } else {
            if (correct) {
              transition += ' correct-enter-active';
            } else {
              transition += ' fade-exit-active';
            }
          }
        }

        return (
          <div className={'answer' + transition} key={i}>
            <div className="letter text-center">{this.letters[i]}</div>
            <div className="answer__text text-center">
              {renderText(answer.nc_quiz_answers_answer_text)}
            </div>
            <div className="text-center">
              <button disabled={!isActive} type="button" className="btn btn-outline-primary answer__button" onClick={onClickAnswer(i)}>wählen</button>
            </div>
          </div>
        );
      }
    );
  }
  render(){
    const question = this.props.nc_quiz_question;
    const answers =  this.getAnswers();

    return (
      <div className={'level' + false !== question.locked && ' is-locked'}>
        <div className="question">
          {renderText(question)}
        </div>
        <div className="answers__wrapper">
          {answers}
        </div>
      </div>
    );
  }
}