import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import BlockButton from './BlockButton';
/**
 * youtube iframe component with blocker for iframe in Single Youtube Post Component
 * for wp-embeded youtube videos
 * @see <SingleVideo/>
 */
@inject('blockDataStore')
@observer
export default class VimeoIFrameSingle extends Component {

  render () {
    const allowYoutube = this.props.blockDataStore.allowYoutube;
    return (
      <div className="-video-wrapper">
        <div className="aspect-content">
          {!allowYoutube ? (
            <BlockButton thumbnail={this.props.img} provider="youtube" onClick={this.props.blockDataStore.onClickAllowYoutube} type="single"/>
          ) : (
            <iframe src={this.props.src} title={this.props.title} width="100%" height="100%" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
              {this.props.title}
            </iframe>
          )}
        </div>
      </div>
    )
  }
}