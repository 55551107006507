import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import { Map, FeatureGroup, GeoJSON } from 'react-leaflet';
import { Browser as LeafletBrowser, Map as LeafletMap } from 'leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import LoadingAnimation from '../../elements/LoadingAnimation/LoadingAnimation';
import SchoolTypeFilter from '../SchoolTypeFilter/SchoolTypeFilter';
import PanesFilter from '../PanesFilter/PanesFilter';
import SchoolsPane from '../SchoolsPane/SchoolsPane';
import CoordinationsPane from '../CoordinationsPane/CoordinationsPane';
import { Collapse } from 'reactstrap';

@inject('dataStore', 'windowStore', 'filterStore')
@observer
class HomeMap extends Component {

  state = {
    lat: 50.93,
    lng: 13.45,
    maxBounds: [
      [52.4, 10.2],
      [49.6, 16.1],
    ],
    bounds: [
      [51.7, 11.8],
      [50.1, 15.1],
    ],
    maxZoom: 11,
    minZoom: 6.5,
    zoom: 7,
    zoomSnap:0.5,
    /* {String} */
    currentHovered: null,
    /* {Object} */
    currentActive: null,
    showMap: false
  };
  canToolTip = false;
  mapRef = React.createRef();
  geoJsonRef = React.createRef();
  geoJsonSlug = 'geoJson';
  attributesSlug = 'attributes';
  twinsJsonSlug = 'twinsJson';
  gestureHandlingText = {
    touch: "Benutzen Sie 2 Finger um die Karte zu bewegen.",
    scroll: "Halten Sie die Taste Strg gedrückt während Sie scrollen, um die Karte zu zoomen.",
    scrollMac: "Halten Sie die \u2318 Taste gedrückt während Sie scrollen, um die Karte zu zoomen."
  }

  constructor(props) {
    super(props);
    LeafletMap.addInitHook("addHandler", "gestureHandling", GestureHandling);
    this.canToolTip = !LeafletBrowser.mobile;
  }


  componentDidMount(){
    this.props.dataStore.fetchIfNeeded(this.geoJsonSlug);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.geoJsonRef.current) {
      this.props.dataStore.geoJsonRef = this.geoJsonRef;
    }
    // console.log(this.mapRef.current.leafletElement.getZoom())
  }

  /**
   * after viewport is resized, existing map should fit in new container size
   */
  windowResizeReaction = reaction(
    () => {
      const store = this.props.windowStore;
      return store.windowRatio;
    },
    (windowRatio) => {
      if (this.mapRef.current) {
        const map = this.mapRef.current.leafletElement;
        map.fitBounds(this.state.bounds);
      }
    }
  );

  dismissPopup = () => {
    if (this.mapRef.current) {
      const map = this.mapRef.current.leafletElement;
      map.closePopup();
      map.fitBounds(this.state.bounds);
    }
  };

  onEachFeature = (feature, layer) => {
    layer.setStyle({fillColor: layer.feature.properties.color, fillOpacity: "0.7",});
  }

  toggle = (toggler) => {
    let togglerStatus = this.state[toggler];
    this.setState({
      [toggler]: !togglerStatus
    });
  }


  render(){
    const store = this.props.dataStore;
    const position = [this.state.lat, this.state.lng];
    const isFetched = store.isFetched.get(this.geoJsonSlug);
    const style = {color: '#003E66', fillColor: '#fff', fillOpacity: "1", weight: 2};
    const maxZoom = this.state.maxZoom;
    const minZoom = this.state.minZoom;
    // console.log(minZoom, maxZoom, this.props.windowStore.isGreaterEqualThan('lg'));

    return (
      <div className="nc-map">
        <div className="d-md-none">
            <div className="nc-map__toggler" onClick={() => this.toggle('isOpen')}>
                <div className="-toggler w-100">Kartenfilter anzeigen</div>
                <div className="flex-shrink-1 -toggler-icon"><span></span></div>
            </div>
            <Collapse isOpen={this.state['isOpen']}>
              <PanesFilter dismissPopup={this.dismissPopup}/>
              <SchoolTypeFilter dismissPopup={this.dismissPopup}/>
            </Collapse>
        </div>
        <Map
          className="nc-map__map"
          center={position}
          zoom={this.state.zoom}
          maxZoom={maxZoom}
          minZoom={minZoom}
          zoomSnap={this.state.zoomSnap}
          zoomDelta={this.state.zoomSnap}
          maxBounds={this.state.maxBounds}
          bounds={this.state.bounds}
          onzoomend={e => {/*console.log(e.target._zoom)*/}}
          ref={this.mapRef}
          attributionControl={false}
          doubleClickZoom={false}
          gestureHandling={true}
          gestureHandlingText = {this.gestureHandlingText}
          >
          <FeatureGroup>
            {isFetched ? 
              <GeoJSON onEachFeature={this.onEachFeature} style={style} data={store.data[this.geoJsonSlug]} className="landkreise" ref={this.geoJsonRef}>
                {/* <Rectangle bounds={this.state.bounds} interactive={false} /> */}
                {/*<Rectangle bounds={this.state.maxBounds} interactive={false} /> */}
              </GeoJSON>
            :
              <LoadingAnimation/>
            }
          </FeatureGroup>
          <SchoolsPane/>
          <CoordinationsPane/>
          
          <div className="d-none d-md-block">
            <SchoolTypeFilter dismissPopup={this.dismissPopup}/>
            <PanesFilter dismissPopup={this.dismissPopup}/>
          </div>
        </Map>
        <div className={ "map-block" + (this.state.showMap ? " d-none" : "") } onClick={() => this.toggle('showMap')}>
              <button className="btn">Karte aktivieren</button>
        </div>
        <ul className="nc-map__legend">
          <li className="nc-blue-mid">Region Leipzig</li>
          <li className="nc-dark-blue">Region Chemnitz</li>
          <li className="nc-very-light-blue">Region Dresden</li>
          <li className="nc-avocado">Region Westsachsen</li>
          <li className="nc-red">Region Ostsachsen</li>
        </ul>
      </div>
    )
  }

}

export default HomeMap;