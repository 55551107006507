import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject('quizStore')
@observer
export default class Dots extends Component {

  circleRef = React.createRef();
  dotDiameter = 20;

  render() {
    const levels = this.props.quizStore.getLevels();
    const history = this.props.quizStore.history;
    const step = this.props.quizStore.currentStep;
    // generate the dots
    const dots = levels.map((level, i) => {
      let color = '';
      if ( i < history.length) {
        color = history[i] ? ' correct' : ' wrong';
      }
      return <div key={i} className={'dot' + color}></div>;
    });
    // where is the circle?
    const factor = Math.min(step, levels.length - 1);
    const circleStyle = {'left': this.dotDiameter * factor};
    
    return (
      <div className="dots-wrapper">
        <div className="dots">
          {dots}
          <div className={'circle'} ref={this.circleRef} style={circleStyle}></div>
        </div>
      </div>
    );
  }

}