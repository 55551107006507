import { action, observable } from 'mobx';

export default class DataStore {
  
  dataSrc = {
    "geoJson": {path: '/content/uploads/data/landkreise_output_p3.json', type: 'json'}
  }

  csvOptions = {
    separator : ';',
    headers: false,
  };

  @observable
  isFetched = new Map();
  isFetching = {};
  data = {};

  /**
   * holds the current ref to GeoJson Layer
   */
  geoJsonRef = {};

  /**
   * @param {object} params 
   */
  @action
  fetch( params ) {
    // check for object param
    let {path, slug, type } = params;
    path = !path && this.dataSrc[slug] ? this.dataSrc[slug].path : path;
    type = !type && this.dataSrc[slug] ? this.dataSrc[slug].type : type;

    this.isFetching[path] = true;

    fetch(path)
      .then((response) => {
        if (response.ok) {
          if ('json' === type) {
            return response.json()
          } else {
            return response.text()
          }
        } else {
          Promise.reject(response.status);
        }
      })
      .then(data => {
        this.isFetching[path] = false;
        if(data) {
          const key = slug ? slug : path;
          if ('csv' === type) {
            // neatCsv(data, this.csvOptions).then(
            //   data => {
            //     this.data[key] = data;
            //     this.isFetched.set(key, true);
            //   }
            // );
          } else {
            this.data[key] = data;
            this.isFetched.set(key, true);
          }
          
        }
      }).catch(error => console.error(error, path));;
  }

  fetchIfNeeded = (slug) => {
    slug = slug ? slug : 'geoJson';
    if ( !this.isFetching[slug] && !this.isFetched.get(slug)) {
      this.fetch({slug: slug});
    }
  }

  
  /**
   * helper function, round to 2 digits 
   * @param {number} value 
   */
  round( value ) {
    return Math.round(value * 100) / 100;
  }

}