import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import BlockButton from './BlockButton';

/**
 * youtube iframe component with blocker for iframe replacement in react html parser
 * for wp-embeded youtube videos
 * @see renderText
 * @see parserOptions
 */
@inject('blockDataStore')
@observer
export default class YoutubeIFrame extends Component {

  render () {
    const allowVimeo = this.props.blockDataStore.allowVimeo;
    if (!allowVimeo) {
      return (
       <BlockButton thumbnail={this.props.thumbnail} provider="vimeo" onClick={this.props.blockDataStore.onClickAllowVimeo}/>
      );
    } else {
      const {title, frameborder, allowfullscreen,  ...attribs } = this.props.attribs;
      return <iframe title={title} frameBorder={frameborder} allowFullScreen={allowfullscreen} {...attribs}></iframe>
    }
  }
}