import React, { Component } from 'react';
import {default as ImgCmp} from 'react-image';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';


class Img extends Component {

  static defaultProps = {
    withLoader : false,
  }

  prefix;
  src;
  pattern = /^https?|^\/\//i;

  constructor(props) {
    super(props);
    if (typeof process.env.REACT_APP_IMG_PROXY_URL !== 'undefined') {
      this.prefix = process.env.REACT_APP_IMG_PROXY_URL;
    }
  }
  prepareSrc = () => {
    if (!this.prefix) {
      this.src = this.props.src;
    } else {
      if (Array.isArray(this.props.src)) {
        this.src = this.props.src.map((srcStr) => {
          return this.concatSrcStr(srcStr);
        }); 
      } else {
        this.src = this.concatSrcStr(this.props.src);
      }
    }
  }

  concatSrcStr(srcStr) {
    if (! this.pattern.test(srcStr)) {
      if (srcStr.charAt(0) === '/') srcStr = srcStr.substr(1);
      return this.src = this.prefix + srcStr;
    } else {
      return srcStr;
    }
  }


  render() {
    if (!this.props.src) {
      return null;
    }
    const {withLoader, ...props} = this.props;
    if (withLoader && !props.loader) {
      props.loader = <LoadingAnimation/>
    }
    
    this.prepareSrc();
    return <ImgCmp {...props} src={this.src}/>;
  }
}

export default Img;