import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Slider from "react-slick";
import Question from './Question';
import Result from './Result';
import Dots from './Dots'


@inject('quizStore')
@observer
export default class Quiz extends Component {

  /**
   * holds the quizStore
   */
  store = {};
  slider = React.createRef();

  // https://github.com/akiran/react-slick/issues/1262
  settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: this.props.quizStore.currentStep,
    afterChange: this.props.quizStore.afterChange,
    swipeToSlide: false,
    adaptiveHeight: false,
    arrows: false,
    dots: false,
    accessibility: false,
    draggable: false,
    swipe: false,
    touchMove: false,
    focusOnSelect: false,
  };

  constructor(props) {
    super(props);
    this.store = props.quizStore;
    this.store.init(props.slug, props.field_nc_quiz_levels, props.field_nc_quiz_evaluations, this.slider);
  }

  componentDidMount(){
    this.store.slider = this.slider;
  }

  componentWillUnmount(){
    this.store.clearTransition();
  }

  render() {
    const levels = this.store.getLevels();
    const evaluations = this.store.getEvaluations();
    const step = this.store.currentStep;
    const score = this.store.score;
    return (
      <div className="nc-quiz">
        <div className="-header">
          <div className="row">
            <div className="col-4 col-lg-3 offset-lg-2">
              {score} {1 !== score ? 'Punkte' : 'Punkt'}
            </div>
            <div className="col-8 col-lg-2 text-lg-center">
              <div className="counter">
                Frage {step+1}/{levels.length}
                <Dots/>
              </div>
            </div>
          </div>
        </div>
        <Slider className="nc-quiz-slider -content" {...this.settings} ref={this.slider}>
          {levels.map((level, i) => 
            <Question key={this.props.slug + i} step={i} {...level} canClick={this.store.canClick}/>
          )}
          <Result key={this.props.slug + levels.length} step={levels.length} evaluations={evaluations} canClick={this.store.canClick}/>
        </Slider>
      </div>
    );
  }
}