import React, { Component } from 'react';
import { Switch, matchPath, Route, Redirect} from 'react-router-dom';
import { observer } from 'mobx-react';
import { ROUTES as routes , RouteWithSubRoutes } from './routes/routes';
import Header from './containers/Header/Header';
import Footer from './containers/Footer/Footer';

@observer
class App extends Component {
  render() {
    const location = this.props.location;
    let match = null;
    for (let i=0; i< routes.length; i++) {
      match = matchPath(location.pathname, routes[i]);
      if (match) {
        break;
      }
    }

    return (
      <div className="wrap container-fluid">        
        <div id="left"></div>
        <div id="right"></div>
        <div id="top"></div>
        <div id="bottom"></div>
        <div id="background-image"></div>
        <Header/>
        <div className="content">
          <main className="nc-main">
            <Switch key={match.path}>
                {/* add a trailing slash to pathname, in dev, in prod server should to */}
                {'development' === process.env.NODE_ENV && <Route exact strict path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`}/>} />}
                {routes.map((route, i) =>(
                  <RouteWithSubRoutes  key={i} {...route} />
                ))}
              </Switch>
          </main>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default App;
