import './helpers/polyfill'
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'mobx-react';
import { Router, Route } from 'react-router-dom';
import {default as stores, history } from './stores/index';

ReactDOM.render(
  <Provider {...stores}  history={history}>
    <Router history={history}>
      <Route path="*" component={App}></Route>
    </Router>
  </Provider>,
  document.getElementById('app_root')
);
registerServiceWorker();
