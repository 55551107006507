import { action, observable } from 'mobx';

export default class NavigationStore {

  /**
   * holds the routerStore
   */
  routerStore = {}

  /**
   * // To stop listening, call the function returned from listen().
   * unlisten();
   */
  unlisten = null;
  primaryNavigationItems = [];
  /**
   * holds current pathname
   */
  pathname = '';
  @observable navIsActive = false;
  @observable isModal = false;
  @observable showFirstAidTab = true;

  constructor(routerStore) {

    if ( typeof window !== 'undefined' && typeof window.NC !== 'undefined' && window.NC.primary_navigation ) {
      this.primaryNavigationItems = window.NC.primary_navigation;
      this.footerNavigationItems = window.NC.footer_navigation;
      this.socialNavigationItems = window.NC.social_navigation;
      this.postTypesRoutes = window.NC.routes;
    }
    this.routerStore = routerStore;
    this.pathname = routerStore.location.pathname;
    // listen to history
    this.unlisten = routerStore.history.listen((location, action) => {
      if (this.pathname !== location.pathname) {
        this.pathname = location.pathname;
        this.closeNav();
      }
    });
  }

  handleClick = (e, item) => {
    const urlComponents = item.url_components;

    // use routerStore only if link is internal
    const homeUrl = this.postTypesRoutes._meta.home_url;
    const isExtern = undefined !== urlComponents.scheme && 
      (
        !/http/i.test(urlComponents.scheme) || 
        (urlComponents.host && !new RegExp(urlComponents.host, 'i').test(homeUrl))
      );
    if (!isExtern) {
      //const backLocation = getBackLocation();
      let path;
      if (!typeof urlComponents === 'object' || !urlComponents.hasOwnProperty('path')) {
        path = item.url;
      } else {
        path = urlComponents.path;
        if (urlComponents.hasOwnProperty('query')) {
          path = path + '?' + urlComponents.query
        }
        if (urlComponents.hasOwnProperty('fragment')) {
          path = path + '#' + urlComponents.fragment
        }
      }
      /*const to = 
        backLocation ?
        {
          pathname: path,
          state: {'backLocation': true}
        } :
        path;*/

      e.preventDefault();
      this.routerStore.push(path);
      this.closeNav();
      return false;
    }
  }

  isCurrentMenuItem = (urlComponents) => {
    const cp = this.routerStore.location.pathname;
    if (typeof urlComponents === 'object' && urlComponents.hasOwnProperty('path')) {
      return (cp === urlComponents.path)
    }
    return  false;
  };

  @action
  toggleNav = () => {
    this.navIsActive = !this.navIsActive;
    // this.navIsActive ? document.body.classList.add('overflow') : document.body.classList.remove('overflow');
  };

  /**
   * on location change / navigate close the menu
   */
  @action
  closeNav = () => {
    this.navIsActive = false;
    document.body.classList.remove('overflow');
  };

  @action
  enableIsModal = () => {
    this.isModal = true;
  }

  @action
  disableIsModal = () => {
    this.isModal = false;
  }

  @action
  toggleFirstAidTab= (isVisible) => {
    this.showFirstAidTab = !isVisible;
  }
}
