import React, { Component, Suspense, lazy} from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle, renderText } from '../../helpers/api';
import  NoMatch  from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/elements/LoadingAnimation/LoadingAnimation';

import CooperationPartners from '../../components/posts/CooperationPartners/CooperationPartners';

const HomeMap = lazy(() => import('../../components/maps/HomeMap/HomeMap'));

@inject('pagesStore')
@observer
class Single extends Component {

  slug = '';
  store = {};

  constructor(props) {
    super(props);
    this.getCurrentSlug();
    this.store = this.props.store ? this.props.store : this.props.pagesStore;
    this.state = {
      rassismus: true,
      antisemitismus: true,
      rechtspopulismus: true,
      nationalsozialismus: true,
      migration: true,
      hatespeech: true,
      mobbing: true,
      toleranz: true,
      demokratiebildung: true,
      verschwoerungsideologien: true,
      sexismus: true,
      klassismus: true,
      partizipation: true,
      nachhaltigkeit: true,
      kolonialismus: true,
      antiromaismus_antiziganismus: true,
      islam_muslimfeindlichkeit: true,
      showPartners: true,
      isOpen: false, 
      setIsOpen: false
    };
  }

  renderHead() {
    if (this.store.isFetched.get(this.slug) && this.store.data.posts[this.slug].title) {
      return renderTitle(this.store.data.posts[this.slug].title);
    } else {
      return null;
    }
  }

  getCurrentSlug = () => {
    if (this.props.match && this.props.match.params) {
      const slug = this.props.match.params.id;
      this.slug = '' !== slug ? slug : 'homepage';
    }
  }
  componentDidMount() {
    this.store.fetchIfNeeded(this.slug);
    document.body.classList.add('page');
  }
  componentWillUnmount() {
    document.body.classList.remove('page');
  }

  componentDidUpdate() {
    this.store.fetchIfNeeded(this.slug);
  }

  toggle = (toggler) => {
    let togglerStatus = this.state[toggler];
    this.setState({
      [toggler]: !togglerStatus
    });
  }

  render() {
    this.getCurrentSlug();
    const isFetched = this.store.isFetched.get(this.slug);
    const post = this.store.data.posts[this.slug];
    const scrollToTop = !this.props.location.hash && (!this.props.location.state || !this.props.location.state.scrollTo);
    // const CooperationPartners = this.CooperationPartners;

    if ((isFetched && false === post) || (this.store.isFetchedAll && !post)) {
      return <NoMatch location={this.props.location} />;
    }

    let terms = '';
    if (isFetched ) {
      post.terms.category && post.terms.category.map((term, i) => {
        terms += 'nc-' + post.terms.category[i].slug + ' ';
        return false;
      })
    }
    return (
      <div className={'nc-page ' + terms}>
        { isFetched ? (
          <div className={'nc-page__content '}>
            {scrollToTop && <ScrollToTop onHistoryPush={true}/>}
            <ScrollToHash/>
            {this.renderHead()}
            {/* {post.thumbnail && (
              <div className="nc-page__header-img overscan">
                <img src={post.thumbnail[0]} alt={post.title} />
              </div>
            )} */}

            <div className="row">
              <div className="col-12 col-lg-10 offset-lg-1 col-xxl-8 offset-xxl-2">
                <div className={post.thumbnail ? 'nc-page__content-wrapper -has-image' : 'nc-page__content-wrapper'}>
                  {post.terms.category && post.terms.category.map((term, i) => {
                    if( 'schulkarte' === term.slug ){
                      return (
                        <div className="nc-show-map" key={i}>
                          <Suspense fallback={<LoadingAnimation/>}>
                            <HomeMap/>
                          </Suspense>    
                        </div>
                      )
                    }
                    return false;
                  })}
                  {renderText(post.content)}
                  {/* {console.log(post.fields.field_koop_partner)} */}
                  {post.fields && post.fields.field_koop_partner && <CooperationPartners post={post.fields.field_koop_partner}/>}
                </div>
              </div>
            </div>

          </div>
        ) : <LoadingAnimation/>
        }
      </div>
    );
  }
}

export default Single;
