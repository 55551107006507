import { action, observable } from 'mobx';

export default class QuizStore {
  levels = {};
  evaluations = {};
  slider = {};
  currentSlug;
  @observable
  score = 0;
  @observable
  currentStep = 0;
  @observable
  canClick = false;
  @observable
  quizComplete = false;
  /**
   * contains hits for given answers
   */
  @observable
  history = [];
  timeout = false;
  transDuration = 5000;
  

  constructor (scrollStore) {
    this.scrollStore = scrollStore;
  }

  /**
   * init quiz data
   * @param {string} slug 
   * @param {array} levels 
   * @param {array} evaluation 
   */
  @action
  init = (slug, levels, evaluation, slider) => {
    if (!this.levels[slug]) {
      this.currentSlug = slug;
      this.slider = slider;
      this.levels[slug] = levels;
      for (let i = 0; i < this.levels[slug].length; i++) {
        this.levels[slug][i].isComplete = false;
        this.levels[slug][i].locked = false;
      }
      this.evaluations[slug] = evaluation;
      this.score = 0;
      this.currentStep = 0;
      this.canClick = true;
      this.quizComplete = false;
    }
  }

  /**
   * reset quiz data, for a replay
   */
  @action
  reset = () => {
    const slug = this.currentSlug;
    for (let i = 0; i < this.levels[slug].length; i++) {
      this.levels[slug][i].isComplete = false;
      this.levels[slug][i].locked = false;
    }
    this.score = 0;
    this.currentStep = 0;
    this.canClick = true;
    this.quizComplete = false;
    this.history.replace([]);
  }

  @action
  setCurrentStep(){
    this.currentStep++;
  }

  @action
  setScore(){
    this.score++;
  }

  getLevels = () => {
    return this.levels[this.currentSlug];
  }
  getLevelsCount = () => {
    return this.levels[this.currentSlug].length;
  }

  getEvaluations = () => {
    return this.evaluations[this.currentSlug];
  }

  handleLevelComplete = (correct) => {
    this.slider.current.slickNext();
    this.history.push(correct);
    if (correct) {
      this.setScore();
    }
  }

  clearTransition = () => {
    // clear the timeout
    clearTimeout(this.timeout);
    if (!this.quizComplete && false !== this.levels[this.currentSlug][this.currentStep].locked) {
      const answer = this.levels[this.currentSlug][this.currentStep].nc_quiz_answers[this.levels[this.currentSlug][this.currentStep].locked];
      this.handleLevelComplete(answer.nc_quiz_answers_answer_zimbo);
      // set next question without animation
      if (this.currentStep < this.getLevelsCount()) {
        this.setCurrentStep();
        this.canClick = true;
      }
    }
  }

  @action
  onClickAnswer(e, index) {
    const answer = this.levels[this.currentSlug][this.currentStep].nc_quiz_answers[index];
    const correct = answer.nc_quiz_answers_answer_zimbo;
    this.levels[this.currentSlug][this.currentStep].locked = index;
    this.canClick = false;
    // last question ?
    if (this.currentStep === this.levels[this.currentSlug].length -1 ) {
      this.quizComplete = true;
    }
    this.timeout = setTimeout(
      () => {
        this.handleLevelComplete(correct);
      }, this.transDuration);
  }

  @action
  onClickReplay= (e) => {
    this.reset();
    this.slider.current.slickNext();
  }

  /**
   * callback for react slick slider afterchange
   * https://github.com/akiran/react-slick/issues/1262
   */
  afterChange = (current) => {
    if (0 !== current) {
      this.setCurrentStep();
      this.canClick = true;
      this.scrollStore.animateScrollTo(
        {desiredOffset: 0}
      );
    }
  };
}