import { action, observable } from 'mobx';
import { matchPath } from 'react-router-dom';
import { getRouteByPath } from '../routes/routes';

export default class PagesStore {

  /**
   * @module wpapi
   * wpapi.WPAPI
   */
  api
  data = {
    posts: {},
    ids: [],
    meta: {},
  };;
  @observable isFetched = new Map();
  isFetching = [];

  constructor( api ) {
    this.api = api;
  }

  /**
   * @todo get rewrite slug from matching route for retrieving custom posttypes, f.e. path: "/videos/:id/"
   * @param {object} params 
   */
  @action
  fetch( params ) {
    // check for object param
    let {path, postType, slug } = params;
    path = !path ? slug : path;
    this.isFetching[path] = true;
    this.api.page = this.api.registerRoute('nc/v1', '/pages/(?P<id>[\\w-]+)', { params: ['param']});
    const fetchParams = {};
    if (postType) {
      fetchParams.post_type = postType;
    }
    this.api.page().param(fetchParams).id(slug)
      .then((post) => {
        if ( post && post.hasOwnProperty('ID') ) {
          this.data.posts[path] = post;
        } else {
          this.data.posts[path] = false;
        }
        this.isFetched.set(path, true);
        this.isFetching[path] = false;
      })
      .catch(error => console.error(error));
  }

  fetchIfNeeded(slug) {
    slug = '' !== slug ? slug : 'homepage';
    if ( !this.isFetching[slug] && !this.isFetched.get(slug)) {
      this.fetch({slug: slug});
    }
  }

  fetchPathIfNeeded( path ) {
    if ( !this.isFetching[path] && !this.isFetched.get(path)) {
      const route = getRouteByPath(path);
      const match = route && matchPath(path, route);
      if (match) {
        const params = {
          path: path,
          slug: match.params.id,
          postType: route.apiParams.postType
        };
        this.fetch(params);
      }
    }
  }
}

