import { action, observable, computed } from 'mobx';

export default class FilterStore {
  @observable categories = new Map();
  @observable panes = new Map(
    [
      ['schools', {
        name: 'Schulen',
        active: true,
      }],
      ['coordinations', {
        name: 'Regionalkoordination',
        active: true,
      }],
    ]
  );

  /**
   * 
   * @param {*} terms 
   */
  @action
  init(terms) {
    for (let index in terms) {
      const term = terms[index];
      const cats = term.Schulart.split('|').map(s => s.trim());
      for (let j in cats){
        const cat = cats[j];
        if (!this.categories.has(cat)) {
          this.categories.set(
            cat,
            {
              name: cat,
              active: true,
            }
          );
        }
      }
    };
  }

  /**
   * @returns bool
   */
  @computed get isMapCatsAllActive() {
    return this.categories.size === this.mapCatsActiveCount;
  }
  @computed get activeCatsAsArray() {
    const cats = [];
    for ( let c of this.categories ) {
      if (c[1].active) {
        cats.push(c[0]);
      }
    }
    return cats;
  }

  /**
   * @returns number
   */
  @computed get mapCatsActiveCount() {
    let active = 0;
    for ( let c of this.categories ) {
      if (c[1].active) {
        active++;
      }
    }
    return active;
  }

  /**
   * @returns bool
   */
  @computed get isMapPanesAllActive() {
    return this.panes.size === this.mapPanesActiveCount;
  }

  /**
   * @returns number
   */
  @computed get mapPanesActiveCount() {
    let active = 0;
    for ( let c of this.panes ) {
      if (c[1].active) {
        active++;
      }
    }
    return active;
  }
}
