import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Slider from "react-slick";
import VisibilitySensor from 'react-visibility-sensor';
import BlockButton from '../../elements/embed/BlockButton';
/**
 * A Instagram scroller, used as Block for Gutenberg Editor, to display items in slick slider
 */

@inject('blockDataStore')
@observer
 
class InstagramScroller extends Component {

  slider = {};

  settings = {
    infinite: false,
    speed: 500,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    adaptiveHeight: true,
    arrows: true,
    dots: false,
  };

  componentDidMount(){
    this.props.blockDataStore.loadInstagramWidgets();
    // window.instgrm.Embeds.process();
  }


  render() {
    const allowInstagram = this.props.blockDataStore.allowInstagram;

    if (!allowInstagram) {
      return (
        <div className="w-100 d-block">
          <div className="nc-slider__container">
            <div className="nc-slider__wrapper nc-slider__visible">
              <div className="nc-slider__blocker">
                <BlockButton provider="instagram" onClick={this.props.blockDataStore.onClickAllowInstagram}/>
              </div>              
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <VisibilitySensor partialVisibility={true} delayedCall={true} offset={{top:100, bottom: 200}}>
          {({isVisible}) =>
            <div className="w-100 d-block">
              <div className="nc-slider__container">
                  <div className={'nc-slider__wrapper' + (isVisible ? ' nc-slider__visible' : ' nc-slider__hidden')}>
                    <Slider {...this.props.attribs} {...this.settings} ref={slider => (this.slider = slider)}>
                      {this.props.children}
                    </Slider>
                </div>
              </div>
            </div>
          }
        </VisibilitySensor>
      );
    }
  }
}

export default InstagramScroller;